import React, {useEffect} from 'react'
import {Route, Routes, useNavigate} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import {CurrentUserContext} from './contexts/CurrentUserContext'
import * as Api from './Api'
import './App.css';
import Login from './Pages/Login/Login'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import NotFoundPage from './components/NotFoundPage/NotFoundPage'
import MyPage from './Pages/MyPage/MyPage'
import CreateTaskPage from './Pages/CreateTaskPage/CreateTaskPage'
import AllTasksPage from './Pages/AllTasksPage/AllTasksPage'
import OneTaskPage from './Pages/OneTaskPage/OneTaskPage'
import UnapprovedTasksPage from './Pages/UnapprovedTasksPage/UnapprovedTasksPage'
import SettingsPopup from './components/Popups/SettingsPopup/SettingsPopup'
import UnapprovePopup from './components/Popups/UnapprovePopup/UnapprovePopup'
import InfoPopup from './components/Popups/InfoPopap/InfoPopap'
import DeletePopup from './components/Popups/DeletePopup/DeletePopup'

import Loading from './components/Loading/Loading'
import {FilterProvider} from "./contexts/filters_context";
import {SolutionPopup} from "./components/Popups/SolutionPopup/SolutionPopup";
import {useSolutionContext} from "./contexts/solution_context";

function App() {
    const { solutionValue, updateSolution } = useSolutionContext()

    const navigate = useNavigate()

    const [currentUser, setCurrentUser] = React.useState({})
    const [isLoggin, setIsLoggin] = React.useState(localStorage.getItem("token"))
    const [unapprovedTasks, setUnapprovedTasks] = React.useState([])
    const [tasks, setTasks] = React.useState([])
    //const [tasksAfterSearch, setTasksAfterSearch] = React.useState([])
    const [departments, setDepartments] = React.useState([])
    const [taskId, setTaskId] = React.useState(0)
    const [tasksOnKanbanToRender, setTasksOnKanbanToRender] = React.useState([])
    const [initiators, setInitiators] = React.useState([])

    const [errorTextForPopap, setErrorTextForPopap] = React.useState("Error")
    const [isErrorsPopupOpen, setIsErrorsPopupOpen] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [error, setError] = React.useState(false)
    const [isSanding, setIsSanding] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const [message, setMessage] = React.useState('')

    const [isSettingsPopup, setIsSettingsPopup] = React.useState(false)
    const [isUnapprovePopup, setIsUnapprovePopup] = React.useState(false)
    const [isInfoPopup, setIsInfoPopup] = React.useState(false)
    const [isDeletePopup, setIsDeletePopup] = React.useState(false)

    const [limit, setLimit] = React.useState(20)

    useEffect(() => {
        if (localStorage.getItem("token") && currentUser) {
          navigate("/task-list/1");
        }
      }, [limit]);

    const userId = currentUser.id

    const addTasks = (amount) => setLimit(amount);

    async function handleLoginSubmit({username, password}) {
        try {
            // Проверяем пользователя
            await Api.checkUser({username});

            // Получаем авторизацию
            const authRes = await Api.authorization({username, password});
            localStorage.setItem("token", JSON.stringify(authRes.access));
            const token = authRes.access;

            // Получаем информацию о пользователе
            const meRes = await Api.getMe(token);
            setCurrentUser(meRes[0]);
            localStorage.setItem("currentUser", JSON.stringify(meRes[0]));
            setIsLoggin(true);
            getTasks();
            navigate('/');
            setError(false);
            setErrorMessage('');
        } catch (err) {
            console.error("Ошибка при выполнении запросов:", err);
            let errorMessage = "У вас нет прав доступа";
            console.log(err.response)
            if (err) {
                errorMessage = "Неправильное имя или пароль, либо у вас нет прав доступа";
            }
            setError(true);
            setErrorMessage(errorMessage);
        }
    }

    function getDepartments() {
        setLoading(true)
        const tokenString = localStorage.getItem("token");
        const token = tokenString ? JSON.parse(tokenString) : null;
        Api.getDepartment(token)
            .then((res) => {
                setDepartments(res)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function getTasks() {
        setLoading(true)
        const currentUser = JSON.parse(localStorage.getItem("currentUser"))
        const tokenString = localStorage.getItem("token");
        const token = tokenString ? JSON.parse(tokenString) : null;
        //console.log(currentUser.can_confirm_all)
        Api.getMytasks(token)
            .then((res) => {
                setTasks(res)
                //setTasksAfterSearch(res)
                setTasksOnKanbanToRender(res)
                if (currentUser.can_confirm_all === true) {
                    setUnapprovedTasks(res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе')));
                    //console.log(res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе')))
                } else {
                    const unapprovedTasksAfterFilter = res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе'));
                    const unapprovedTasksAfterComparison = unapprovedTasksAfterFilter.filter((i) => currentUser.department.includes(i.department));
                    setUnapprovedTasks(unapprovedTasksAfterComparison);
                }
                //const arrayWithDuplicates = res.map((i) => i.owner.name)
                const arrayWithDuplicates = res.filter(i => i.owner !== null && i.owner !== undefined)
                    .map(i => i.owner.name);
                const uniqueSet = new Set(arrayWithDuplicates)
                const uniqueArray = [...uniqueSet]
                setInitiators(uniqueArray)
                setLoading(false)
            })
            .catch((err) => {
                if(err.toString().includes("401")){
                    console.log("заходит в 1")
                    signOut()
                }
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function createTask(name, text, selectedDepartmentId) {
        setLoading(true)
        Api.createTask(name, text, selectedDepartmentId)
            .then((res) => {
                setLoading(false)
                openInfoPopup()
                setMessage('Вы удачно добавили задачу')
                getTasks()
                navigate("/")
            })
            .catch((err) => {
                openInfoPopup()
                setMessage('Ошибка, попробуйте снова')
                setLoading(false)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const signOut = () => {
        localStorage.clear();
        setIsLoggin(false)
        setCurrentUser({})
        setTasks([])
        setInitiators([])
        setTasksOnKanbanToRender([])
        setIsErrorsPopupOpen(false)
        setErrorTextForPopap('')
        navigate('/sign-in')
    }

    function approveTask(task_id) {
        setLoading(true)
        Api.approveTask(task_id)
            .then((res) => {
                setTasks(prevTasks =>
                    prevTasks.map(task =>
                        task.task_id === task_id ? {...task, confirm: true} : task
                    )
                );
                setTasksOnKanbanToRender(prevTasks =>
                    prevTasks.map(task =>
                        task.task_id === task_id ? {...task, confirm: true} : task
                    ).filter((item) => item.confirm === true)
                );
                setUnapprovedTasks(unapprovedTasks.filter((i) => i.task_id !== task_id))
                setTaskId(0)
                closeAllPopups()
                setLoading(false)
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false)
            })
    }

    React.useEffect(() => {
        getTasks()
        // setLoading(true)
        // const currentUser = JSON.parse(localStorage.getItem("currentUser"))
        // const token = JSON.parse(localStorage.getItem("token"))
        //
        // if (token) {
        //
        //     Api.getMytasks(token)
        //         .then((res) => {
        //             localStorage.setItem("tasks", JSON.stringify(res))
        //             setTasks(res)
        //             if (currentUser.can_confirm_all === true) {
        //                 //console.log(res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе')))
        //                 setUnapprovedTasks(res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе')));
        //             } else {
        //                 const unapprovedTasksAfterFilter = res.filter((i) => i.department !== null && i.confirm === false && (i.status === 'Создана' || i.status === 'В работе'));
        //                 const unapprovedTasksAfterComparison = unapprovedTasksAfterFilter.filter((i) => currentUser.department.includes(i.department));
        //                 setUnapprovedTasks(unapprovedTasksAfterComparison);
        //             }
        //             const arrayWithDuplicates = res.filter(i => i.owner !== null && i.owner !== undefined)
        //                 .map(i => i.owner.name)
        //             const uniqueSet = new Set(arrayWithDuplicates)
        //             const uniqueArray = [...uniqueSet]
        //             setInitiators(uniqueArray)
        //             setTasksOnKanbanToRender(res)
        //             setIsLoggin(true)
        //             setCurrentUser(JSON.parse(localStorage.getItem("currentUser")))
        //         })
        //         .then(() => {
        //             Api.getMe(token)
        //                 .then((res) => {
        //                     setCurrentUser(res[0])
        //                 })
        //             setLoading(false)
        //         })
        //         .catch((err) => {
        //             console.log("Ошибка при получении контента:", err)
        //             if(err.toString().includes("401")){
        //                 console.log("заходит в 2")
        //                 signOut()
        //             }
        //             setLoading(false)
        //         })
        // } else {
        //     signOut()
        //     setLoading(false)
        // }
    }, [])

    function deleteTask(task_id) {
        setLoading(true)
        Api.deleteTask(task_id)
            .then((res) => {
                setUnapprovedTasks(unapprovedTasks.filter((i) => i.task_id != task_id))
                getTasks()
                closeAllPopups()
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    function showApprivedTasksKanban() {
        setTasksOnKanbanToRender(tasks.filter((item) => item.confirm === true))
    }

    function showUnApprivedTasksKanban() {
        setTasksOnKanbanToRender(tasks.filter((item) => item.confirm === false))
    }

    function showAllTasksKanban() {
        setTasksOnKanbanToRender(tasks)
    }

    function openSettingsPopup() {
        setIsSettingsPopup(true)
    }

    function openInfoPopup() {
        setIsInfoPopup(true)
    }

    function openIsUnapprovePopup(task_id) {
        setIsUnapprovePopup(true)
        setTaskId(task_id)
    }

    function openDeletePopup(task_id) {
        setIsDeletePopup(true)
        setTaskId(task_id)
    }

    function closeAllPopups() {
        setIsSettingsPopup(false)
        setIsUnapprovePopup(false)
        setIsInfoPopup(false)
        setIsDeletePopup(false)
    }

    return (
        <CurrentUserContext.Provider value={currentUser}>
            <FilterProvider>
                {solutionValue && solutionValue.length!==0 && <SolutionPopup/>}
                <div className="App">
                    <Header
                        isLoggin={isLoggin}
                        onSignOut={signOut}
                        unapprovedTasks={unapprovedTasks} //для того чтобы определить есть ли таки на согласование
                    />
                    <div className="App-main-part">
                        <Routes>
                            <Route
                                path='/sign-in'
                                exact element={
                                <ProtectedRoute isLoggin={isLoggin}>
                                    <Login
                                        onSubmit={handleLoginSubmit}
                                        error={error}
                                        errorMessage={errorMessage}
                                    />
                                </ProtectedRoute>
                            }>
                            </Route>

                            <Route
                                exact path="/"
                                element={
                                    <ProtectedRoute isLoggin={isLoggin}>
                                        <MyPage
                                            update={getTasks}
                                            tasksOnKanbanToRender={tasksOnKanbanToRender}
                                            showApprivedTasksKanban={showApprivedTasksKanban}
                                            showUnApprivedTasksKanban={showUnApprivedTasksKanban}
                                            showAllTasksKanban={showAllTasksKanban}
                                        />
                                    </ProtectedRoute>
                                }>
                            </Route>

                            <Route
                                exact path='/create-task'
                                element={
                                    <ProtectedRoute isLoggin={isLoggin}>
                                        <CreateTaskPage
                                            createTask={createTask}
                                            getDepartments={getDepartments}
                                            departments={departments}
                                        />
                                    </ProtectedRoute>
                                }>
                            </Route>

                            <Route
                                exact path='/task-list/:page'
                                element={
                                    <ProtectedRoute isLoggin={isLoggin}>
                                        <AllTasksPage
                                            update={getTasks}
                                            tasks={tasks}
                                            isSettingsPopup={isSettingsPopup}
                                            closeAllPopups={closeAllPopups}
                                            initiators={initiators}
                                            addTasks={addTasks}
                                            onOpenSettingsPopup={openSettingsPopup}
                                            limit={limit}
                                        />
                                    </ProtectedRoute>
                                }>
                            </Route>

                            <Route
                                exact path='/unapproved-tasks'
                                element={
                                    <ProtectedRoute isLoggin={isLoggin}>
                                        <UnapprovedTasksPage
                                            openIsUnapprovePopup={openIsUnapprovePopup}
                                            unapprovedTasks={unapprovedTasks}
                                            openDeletePopup={openDeletePopup}
                                        />
                                    </ProtectedRoute>
                                }>
                            </Route>

                            <Route
                                exact path='/task/:task_id'
                                element={
                                    <ProtectedRoute isLoggin={isLoggin}>
                                        <OneTaskPage
                                            tasks={tasks}
                                        />
                                    </ProtectedRoute>
                                }>
                            </Route>


                            <Route
                                path="*"
                                element={
                                    <NotFoundPage/>
                                }>
                            </Route>

                        </Routes>


                        <UnapprovePopup
                            isOpen={isUnapprovePopup}
                            onClose={closeAllPopups}
                            approveTask={approveTask}
                            taskId={taskId}
                        />

                        <DeletePopup
                            isOpen={isDeletePopup}
                            onClose={closeAllPopups}
                            deleteTask={deleteTask}
                            taskId={taskId}
                        />

                        <InfoPopup
                            isOpen={isInfoPopup}
                            onClose={closeAllPopups}
                            message={message}
                        />

                        <Loading
                            loading={loading}
                        />
                    </div>
                    <Footer></Footer>
                </div>
            </FilterProvider>
        </CurrentUserContext.Provider>
    );
}

export default App;